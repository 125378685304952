import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import router from "./router";
import store from './store'
import './assets/style/custom.css';

import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';

import VueTelInputVuetify from 'vue-tel-input-vuetify/lib';

Vue.config.productionTip = false;

Vue.use(VueTelInputVuetify, {
  vuetify,
});

const options = {
  confirmButtonColor: '#101928',
  cancelButtonColor: '#9d9d9d',
};

Vue.prototype.$currency = 'ر.س';

Vue.use(VueSweetalert2, options);

new Vue({
  vuetify,
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
