import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

function guard(to, from, next){
	if((localStorage.getItem('user-name') && to.path !== '/login') || to.path == '/providers/public-book-appointment') {
        next(); // allow to enter route
    } else if(localStorage.getItem('user-name') && to.path == '/login') {
		next('/');
	} else {
		next('/login');
	}
}

const routes = [
	{
		path: '/',
		name : 'Home',
		component: () => import('@/Index'),
		beforeEnter: guard,
		children: [
			{
				name: 'Calendar',
				path: '/calendar',
				component: () => import('@/pages/Calendar.vue'),
			},
			{
				name: 'Daily Sales',
				path: '/daily-sales',
				component: () => import('@/pages/DailySales.vue')
			},
			{
				name: 'Appointment List',
				path: '/appointment-list',
				component: () => import('@/pages/AppointmentList.vue')
			},
			{
				name: 'Sales History',
				path: '/sales-history',
				component: () => import('@/pages/SalesHistory.vue')
			},
			{
				name: 'Products',
				path: '/products',
				component: () => import('@/pages/Products.vue')
			},
			{
				name: 'Services',
				path: '/services',
				component: () => import('@/pages/Services.vue')
			},
			{
				name: 'Vouchers',
				path: '/vouchers',
				component: () => import('@/pages/Vouchers.vue')
			},
			{
				name: 'Memberships',
				path: '/memberships',
				component: () => import('@/pages/Memberships.vue')
			},
			{
				name: 'Clients',
				path: '/clients',
				component: () => import('@/pages/Clients.vue')
			},
			{
				name: 'Stocktakes',
				path: '/stocktakes',
				component: () => import('@/pages/Stocktakes.vue')
			},
			{
				name: 'Purchase Order',
				path: '/purchase',
				component: () => import('@/pages/Purchase.vue')
			},
			{
				name: 'Supplier',
				path: '/supplier',
				component: () => import('@/pages/Supplier.vue')
			},
		]
	},
	{
		path: '/login',
		name: 'login',
		beforeEnter: async (to, from, next) => {
			let secret = localStorage.getItem('user-name');
			if (secret == null) {
			localStorage.removeItem('user-name');
				next()
			}else if(localStorage.getItem('user-name') && to.path == '/login'){
				next('/calendar')
			} else {
				return next(from);
			}
		},
		component: () => import('@/user/Login')
	},
	{
		path: '/appointment',
		name: 'New Appointment',
		beforeEnter: guard,
		component: () => import('@/pages/Appointment')
	},
	{
		path: '/create_client',
		name: 'Create Client',
		beforeEnter: guard,
		component: () => import('@/pages/Client')
	},
	{
		path: '/view_appointment/:appointment_id/:employeeId',
		name: 'View appointment',
		beforeEnter: guard,
		component: () => import('@/pages/ViewAppointment')
	},
	{
		path: '/edit-appointment/:appointment_id',
		name: 'Edit Appointment',
		beforeEnter: guard,
		component: () => import('@/pages/Appointment')
	},
	{
		path: '/checkout/:appointment_id/:employeeId',
		name: 'Checkout',
		beforeEnter: guard,
		component: () => import('@/pages/Checkout')
	},
	{
		path: '/invoice/:invoice_id',
		name: 'View Invoice',
		beforeEnter: guard,
		component: () => import('@/pages/Invoice')
	},
	{
		path: '/calendar_check',
		name: 'Calendar Check',
		beforeEnter: guard,
		component: () => import('@/pages/CalendarCheck')
	},
	{
		path: '/apply_payment/:invoice_id',
		name: 'Apply Payment',
		beforeEnter: guard,
		component: () => import('@/pages/ApplyPayment')
	},
	{
		path: '/providers/public-book-appointment',
		name: 'Public Book Appointment',
		component: () => import('@/pages/PublicPage')
	},
	{
		path: '/appointment_confirmation/:appointment_id',
		// path: '/appointment_confirmation',
		name: 'Appointment Confirmation',
		component: () => import('@/pages/AppointmentConfirmation')
	},
	{
		path: '/new-product',
		name: 'New Product',
		beforeEnter: guard,
		component: () => import('@/pages/NewProduct')
	},
	{
		path: '/new-service',
		name: 'New Service',
		beforeEnter: guard,
		component: () => import('@/pages/NewService')
	},
	{
		path: '*',
		beforeEnter: guard,
		component: () => import('@/Default')
	}
];

export default new VueRouter({
	mode: "history",
	routes,
});
