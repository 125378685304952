import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import SearchSvg from '../svg/SearchSvg';
import AddVoucherSvg from '../svg/AddVoucherSvg';
import EmptyStateSvg from '../svg/EmptyStateSvg';
import PaymentSvg from '../svg/PaymentSvg';
import CheckVoucherSvg from '../svg/CheckVoucherSvg';
import CompletedSvg from '../svg/CompletedSvg';
import AppointmentUnPaidSvg from '../svg/AppointmentUnPaidSvg';
import AppointmentPaidSvg from '../svg/AppointmentPaidSvg';
import UnPaidInvoiceSvg from '../svg/UnPaidInvoiceSvg';
import LocationAvatarSvg from '../svg/LocationAvatarSvg';
import NoPreferenceSvg from '../svg/NoPreferenceSvg';

Vue.use(Vuetify);

export default new Vuetify({
    icons: {
        values: {
            SearchSvg: { 
                component: SearchSvg, 
            },
            AddVoucherSvg: {
                component: AddVoucherSvg,
            },
            EmptyStateSvg: {
                component: EmptyStateSvg,
            },
            PaymentSvg: {
                component: PaymentSvg,
            },
            CheckVoucherSvg: {
                component: CheckVoucherSvg,
            },
            CompletedSvg: {
                component: CompletedSvg,
            },
            AppointmentUnPaidSvg: {
                component: AppointmentUnPaidSvg,
            },
            AppointmentPaidSvg: {
                component: AppointmentPaidSvg,
            },
            UnPaidInvoiceSvg: {
                component: UnPaidInvoiceSvg,
            },
            LocationAvatarSvg: {
                component: LocationAvatarSvg
            },
            NoPreferenceSvg: {
                component: NoPreferenceSvg
            }
        },
    },
    // currency : {
    //     values: { 
    //         sym : 'ر.س' 
    //     }
    // }
});
