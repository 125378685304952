<template>
  <v-app>
  <router-view />
  <Loader />
  </v-app>

</template>

<script>
import Loader from "./components/Loader/Loader";
  export default {
    name: 'App',
    components:{
      Loader
    }
  }
</script>
